<template>
  <v-container v-if="isLoaded">

    <v-card class="mt-5">
      <base-card-title
        label="Search shipping lead time"
        icon="mdi-calendar-clock"/>
      <v-card-text class="pl-8 pr-8">
        <v-row class="pt-3">
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-flag
              :items="geographicalCountries"
              item-value="id"
              item-text="description"
              item-country="code"
              item-label="Country of delivery"
              :selected-values="shippingLeadTimeSearchCriteria.countryOfDeliveryIds"
              @change="shippingLeadTimeSearchCriteria.countryOfDeliveryIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-flag
              :items="geographicalCountries"
              item-value="id"
              item-text="description"
              item-country="code"
              item-label="Place of loading country"
              :selected-values="shippingLeadTimeSearchCriteria.placeOfLoadingCountryIds"
              @change="shippingLeadTimeSearchCriteria.placeOfLoadingCountryIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="placesOfLoading"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Place of loading"
              :selected-value="shippingLeadTimeSearchCriteria.placeOfLoadingId"
              @change="shippingLeadTimeSearchCriteria.placeOfLoadingId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-flag
              :items="placeOfDeliveryCountries"
              item-value="id"
              item-text="description"
              item-country="code"
              item-label="Place of delivery country"
              :selected-values="shippingLeadTimeSearchCriteria.placeOfDeliveryCountryIds"
              @change="shippingLeadTimeSearchCriteria.placeOfDeliveryCountryIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="placesOfDelivery"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Place of delivery"
              :selected-value="shippingLeadTimeSearchCriteria.placeOfDeliveryId"
              @change="shippingLeadTimeSearchCriteria.placeOfDeliveryId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-freight-service
              :grouped-freight-services="groupedFreightServices"
              :freight-services="freightServices"
              item-label="Mode of transport"
              :selected-value="shippingLeadTimeSearchCriteria.freightServiceIds"
              @change="shippingLeadTimeSearchCriteria.freightServiceIds = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-service-providers
              :service-providers="serviceProviders"
              :selected-value="shippingLeadTimeSearchCriteria.serviceProviderId"
              @change="shippingLeadTimeSearchCriteria.serviceProviderId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-text-field
              item-label="Carrier service string"
              :value="shippingLeadTimeSearchCriteria.service"
              @blur="shippingLeadTimeSearchCriteria.service = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select
              :items="serviceCodes"
              item-value="name"
              item-text="description"
              item-label="Service code"
              :selected-value="shippingLeadTimeSearchCriteria.serviceCode"
              @change="shippingLeadTimeSearchCriteria.serviceCode = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select
              :items="type"
              item-label="Type"
              item-text="type"
              item-value="value"
              :selected-value="shippingLeadTimeSearchCriteria.main"
              @change="shippingLeadTimeSearchCriteria.main = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <v-checkbox
              class="mt-0"
              v-model="shippingLeadTimeSearchCriteria.active"
              input-value="searchCriteria.active"
              @change="shippingLeadTimeSearchCriteria.active = arguments[0]"
              label="Only active lead times"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="mt-2">
      <base-input-search-and-clear-buttons
        class="d-inline"
        @search="search"
        @reset="reset"/>

      <base-input-primary-button
          label="Total report"
          icon="mdi-microsoft-excel"
          class="ml-2"
          @click="downloadTotalReport"/>

      <base-input-primary-button
        label="Diff report"
        icon="mdi-microsoft-excel"
        class="ml-2"
        @click="openDiffReportDialog"/>
    </div>

    <v-card class="mt-10">
      <base-table-paged-server-side
        ref="table"
        :search-criteria="shippingLeadTimeSearchCriteria"
        :table-headers="tableHeaders"
        search-result-name="shippingLeadTimeSearchResults"
        url="/api/touchpoint/shipping-lead-time/criteria-search">

        <!-- slots -->
        <template v-slot:item.countryOfDelivery="{ item }">
          <v-tooltip bottom color="red" >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                 <base-label-with-flag :item-country="item.countryOfDelivery.code" :item-label="item.countryOfDelivery.code"/>
              </span>
            </template>
            <span>{{item.countryOfDelivery.name}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.placeOfLoading="{ item }">
          <v-tooltip bottom color="red" >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                 <base-label-with-flag :item-country="item.placeOfLoading.location.code" :item-label="item.placeOfLoading.unlocode"/>
              </span>
            </template>
            <span>{{item.placeOfLoading.description}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.freightService="{ item }">
          <base-label-with-freight-service :freight-service="item.freightService"/>
        </template>
        <template v-slot:item.placeOfDelivery="{ item }">
          <v-tooltip bottom color="red" >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <base-label-with-flag :item-country="item.placeOfDelivery.location.code" :item-label="item.placeOfDelivery.unlocode"/>
              </span>
            </template>
            <span>{{item.placeOfDelivery.description}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.totalDays="{ item }">
          <v-tooltip v-if="item.hasAdditionalShippingLeadTimes" bottom color="red" >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <span> {{ item.totalDays }}</span> <span><v-icon>mdi-alarm</v-icon></span>
              </span>
            </template>
            <div v-for="interval in item.additionalShippingLeadTimeIntervalsDetails" v-bind:key="interval.validFrom">
              {{ interval }}
            </div>
          </v-tooltip>
          <template v-else>
            {{ item.totalDays }}
          </template>
        </template>
        <template v-slot:item.totalWeeks="{ item }">
          {{ item.totalWeeks }}
        </template>

        <template v-slot:item.actions="{ item }">
          <base-tool-tip
              label="View detail"
              icon="mdi-magnify"
              @click="showViewDialog(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="isAdmin(getUserRoles)"
            label="Edit"
            icon="mdi-pencil"
            @click="update(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="isAdmin(getUserRoles)"
            label="Copy"
            icon="mdi-content-copy"
            @click="copyShippingLeadTime(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="!item.successor && isAdmin(getUserRoles)"
            label="Replace"
            icon="mdi-file-replace-outline"
            @click="replace(item)">
          </base-tool-tip>
          <base-tool-tip v-if="item.main && isAdmin(getUserRoles) && !item.successor"
            label="Create shipping & logistic lead time"
            icon="mdi-truck-delivery-outline"
            @click="createLogisticsLeadTime(item)">
          </base-tool-tip>
          <template v-if="item.eligibleForDelete && isAdmin(getUserRoles)">
            <base-tool-tip
              label="Delete"
              icon="mdi-delete"
              @click="deleteConfirmation(item)">
            </base-tool-tip>
          </template>
        </template>
      </base-table-paged-server-side>
    </v-card>
    <search-shipping-lead-time-view-details-dialog
      :can-update="isAdmin(getUserRoles)"
      :view-dialog="viewDialog"
      :view-dialog-shipping-lead-time="viewDialogShippingLeadTime"
      @close="closeViewDialog"/>

    <base-dialog-confirm
      :confirm-dialog="deleteDialogConfirm"
      label="Do you want to delete shipping lead time ?"
      @confirm="deleteShippingLeadTime()"
      @cancel="() => this.deleteDialogConfirm = false">
    </base-dialog-confirm>

    <base-dialog-ok
      label="Shipping lead time deleted successfully."
      @ok="closeDeleteDialog"
      :dialog="deleteDialogOk"/>

    <base-dialog-ok
      :label="okDialogMessage"
      @ok="closeOkDialog"
      :show-icon="false"
      :dialog="showOkDialog"/>

    <v-form ref="diff-dates-form">
      <base-dialog-plain
        :dialog="diffReportDialog"
        :persistent="true"
        @cancel="() => this.diffReportDialog = false"
        @ok="downloadDiffReport">
        <v-card-text>
          <v-row justify="center" class="pt-16 mb-5">
            Choose two dates to compare (other selected search criteria will be used as well)
          </v-row>
          <v-row justify="center">
            <v-col cols="6">
              <base-input-select-date-picker
                item-label="Select date"
                :rules="[rules.required]"
                @change="diffCompareDateFirst = arguments[0]"/>
            </v-col>
            <v-col cols="6">
              <base-input-select-date-picker
                item-label="Select date"
                :rules="[rules.required, rules.secondDateIsBeforeFirstDate]"
                @change="diffCompareDateSecond = arguments[0]"/>
            </v-col>
          </v-row>
        </v-card-text>
    </base-dialog-plain>
    </v-form>
  </v-container>
</template>

<script>
import BaseTablePagedServerSide from '@/base/table/BaseTablePagedServerSide'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import axios from 'axios'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseInputSearchAndClearButtons from '@/base/input/BaseInputSearchAndClearButtons'
import BaseInputMultiSelectWithFlag from '@/base/input/BaseInputMultiSelectWithFlag'
import BaseInputMultiSelectWithFreightService from '@/base/input/BaseInputMultiSelectWithFreightService'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseInputSelect from '@/base/input/BaseInputSelect'
import SearchShippingLeadTimeViewDetailsDialog from '@/components/shippingleadtime/searchshippingleadtime/SearchShippingLeadTimeViewDetailsDialog'
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseInputTextField from '@/base/input/BaseInputTextField'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import BaseDialogConfirm from '@/base/dialog/BaseDialogConfirm'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import { arraysUtils, security } from '@/mixins/utilsMixin'
import { mapGetters } from 'vuex'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseDialogPlain from '@/base/dialog/BaseDialogPlain'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import { validateDate } from '@/validation/customvalidation'
import BaseInputSelectServiceProviders from '@/base/input/BaseInputSelectServiceProviders'

export default {
  name: 'SearchShippingLeadTime',
  components: {
    BaseInputSelectServiceProviders,
    BaseInputTextField,
    BaseDialogConfirm,
    BaseInputSelectWithFlag,
    SearchShippingLeadTimeViewDetailsDialog,
    BaseInputSelect,
    BaseLabelWithFreightService,
    BaseInputMultiSelectWithFreightService,
    BaseInputMultiSelectWithFlag,
    BaseInputSearchAndClearButtons,
    BaseLabelWithFlag,
    BaseCardTitle,
    BaseTablePagedServerSide,
    BaseToolTip,
    BaseDialogOk,
    BaseInputPrimaryButton,
    BaseDialogPlain,
    BaseInputSelectDatePicker
  },
  mixins: [arraysUtils, security],
  data () {
    return {
      isLoaded: false,
      showDelete: true,
      deleteDialogConfirm: false,
      deleteDialogOk: false,
      diffReportDialog: false,
      showOkDialog: false,
      okDialogMessage: '',
      shippingLeadTimeId: '',
      diffCompareDateFirst: '',
      diffCompareDateSecond: '',
      validate: false,
      type: [
        { type: 'All', value: '' },
        { type: 'Main', value: true },
        { type: 'Non-main', value: false }
      ],
      activeStatus: [
        { status: 'All', value: '' },
        { status: 'Active only', value: true }
      ],
      viewDialog: false,
      viewDialogShippingLeadTime: {},
      tableHeaders: [
        { text: 'Country of delivery', align: 'start', sortable: true, value: 'countryOfDelivery' },
        { text: 'Place of loading', align: 'start', sortable: true, value: 'placeOfLoading' },
        { text: 'Mode of transport', sortable: true, value: 'freightService' },
        { text: 'Place of delivery', sortable: true, value: 'placeOfDelivery' },
        { text: 'TSP', sortable: true, value: 'serviceProvider.code' },
        { text: 'Main', sortable: true, value: 'mainAsString' },
        { text: 'Service code', sortable: true, value: 'serviceCode.description' },
        { text: 'Carrier service string', sortable: true, value: 'service' },
        { text: 'Total days', sortable: true, value: 'totalDays' },
        { text: 'Total weeks', sortable: false, value: 'totalWeeks' },
        { text: 'Valid from', sortable: false, value: 'validFrom' },
        { text: 'Valid to', sortable: false, value: 'validTo' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ],
      rules: {
        required: value => {
          if (this.validate) {
            return !!value || 'Required.'
          } else {
            return true
          }
        },
        secondDateIsBeforeFirstDate: value => validateDate(this.diffCompareDateFirst, value) || 'Compare date second cannot be before compare date first.'
      }
    }
  },
  computed: {
    ...mapGetters([
      'freightServices',
      'groupedFreightServices',
      'serviceProviders',
      'placesOfLoading',
      'geographicalCountries',
      'placesOfDelivery',
      'placeOfDeliveryCountries',
      'serviceCodes',
      'shippingLeadTimeSearchCriteria',
      'getUserRoles'
    ])
  },

  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllFreightServices'),
      this.$store.dispatch('getAllGroupedFreightServices'),
      this.$store.dispatch('getAllServiceProviders'),
      this.$store.dispatch('getAllPlacesOfLoading'),
      this.$store.dispatch('getAllGeographicalCountries'),
      this.$store.dispatch('getAllPlacesOfDelivery'),
      this.$store.dispatch('getPlaceOfDeliveryCountries'),
      this.$store.dispatch('getAllServiceCodes')
    ]).then(() => { this.isLoaded = true })
  },
  methods: {
    search () {
      this.$refs.table.doSearch()
    },
    reset () {
      this.$refs.table.doReset()
      this.$store.commit('resetShippingLeadTimeSearchCriteria')
    },
    showViewDialog (shippingLeadTime) {
      this.viewDialogShippingLeadTime = shippingLeadTime
      this.viewDialog = true
    },
    closeViewDialog () {
      this.viewDialog = false
      this.viewDialogShippingLeadTime = {}
    },
    update (item) {
      this.$router.push({ path: '/ShippingLeadTime/Update/' + item.id })
    },
    replace (item) {
      this.$router.push({ path: '/ShippingLeadTime/Replace/' + item.id })
    },
    createLogisticsLeadTime (item) {
      this.$router.push({ path: '/LogisticsLeadTime/Create/' + item.id })
    },
    copyShippingLeadTime (item) {
      this.$router.push({ path: '/ShippingLeadTime/Create/' + item.id })
    },
    deleteConfirmation (item) {
      this.shippingLeadTimeId = item.id
      this.deleteDialogConfirm = true
    },
    deleteShippingLeadTime () {
      this.deleteDialogConfirm = false
      axios.delete('/api/touchpoint/shipping-lead-time/' + this.shippingLeadTimeId)
        .then(response => {
          this.$refs.table.doSearch()
          this.deleteDialogOk = true
        })
        .catch(e => console.log(e))
    },
    closeDeleteDialog () {
      this.deleteDialogOk = false
    },
    closeOkDialog () {
      this.showOkDialog = false
    },
    downloadTotalReport () {
      const fileName = 'TotalShippingLeadTimeReport.xlsx'
      axios.post('/api/touchpoint/shipping-lead-time/download-total-report',
        this.removeEmptyArrays(this.shippingLeadTimeSearchCriteria), {
          headers: { 'Content-Disposition': 'attachment; filename=' + fileName },
          responseType: 'arraybuffer'
        }
      ).then((response) => {
        if (response.status === 204) { // no content
          this.okDialogMessage = 'No shipping lead times found for selected criteria.'
          this.showOkDialog = true
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        }
      })
        .catch((error) => console.log(error))
    },
    downloadDiffReport () {
      this.validate = true
      if (this.$refs['diff-dates-form'].validate()) {
        this.diffReportDialog = false

        const fileName = 'ShippingLeadTimeDifferenceBetweenDatesReport.xlsx'
        axios.post('/api/touchpoint/shipping-lead-time/download-diff-report/' + this.diffCompareDateFirst + '/' + this.diffCompareDateSecond + '',
          this.removeEmptyArrays(this.shippingLeadTimeSearchCriteria), {
            headers: { 'Content-Disposition': 'attachment; filename=' + fileName },
            responseType: 'arraybuffer'
          }
        ).then((response) => {
          if (response.status === 204) { // no content
            this.okDialogMessage = 'No shipping lead times found for given dates and selected criteria .'
            this.showOkDialog = true
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
          }
        })
          .catch((error) => console.log(error))
      }
    },
    openDiffReportDialog () {
      this.diffCompareDateFirst = ''
      this.diffCompareDateSecond = ''
      this.diffReportDialog = true

      if (this.$refs['diff-dates-form']) {
        this.$refs['diff-dates-form'].reset()
      }
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 100%;
}
</style>
